interface AlertProps {
  text: string;
}

export default function Alert(props: AlertProps) {
  const { text } = props;

  return (
    <div className="block bg-mary_rose rounded-lg text-size12 leading-15px text-sun_scarlet p-4 mb-2">
      {text}
    </div>
  );
}
