import React from 'react';
import Link from 'next/link';
import { GetPageLink } from '@/utility/getLinkPage';
import { useRouter } from 'next/router';

interface HeroImageProps {
  type: string;
}

export default function HeroImage(props: HeroImageProps) {
  const { type } = props;
  const router = useRouter();

  return type == 'small' ? (
    <div className="relative hidden sm:block bg-hero_image_small shadow-hero_image_small bg-no-repeat bg-center bg-cover flex-none w-1/5 min-w-hero_image_small max-w-hero_image_small px-32px py-64px">
      <div className="block text-center">
        <Link href={GetPageLink('/', router.query)}>
          <a>
            <img
              src="/assets/images/logo_idn.svg"
              width={41}
              className="max-w-full mx-auto"
              alt="logo idn"
            />
          </a>
        </Link>
      </div>
    </div>
  ) : (
    <div className="relative hidden sm:flex items-center justify-center bg-hero_image shadow-hero_image bg-no-repeat bg-center bg-cover flex-none w-42% max-w-hero_image px-85px py-64px md:px-32px md:py-32px">
      <div className="absolute top-32px left-32px md:left-32px md:top-32px lg:top-32px lg:left-32px">
        <Link href={GetPageLink('/', router.query)}>
          <a>
            <img
              src="/assets/images/logo_idn.svg"
              width={41}
              className="max-w-full"
              alt="logo idn"
            />
          </a>
        </Link>
      </div>
      <ul className="logo-list py-10">
        <li className="logo-list-item mb-5">
          <img
            src="/assets/images/logo_idntimes.png"
            className="max-w-full mx-auto"
            alt="logo idntimes"
          />
        </li>
        <li className="logo-list-item mb-5">
          <img
            src="/assets/images/logo_popbela.png"
            className="max-w-full mx-auto"
            alt="logo popbela"
          />
        </li>
        <li className="logo-list-item mb-5">
          <img
            src="/assets/images/logo_popmama.png"
            className="max-w-full mx-auto"
            alt="logo popmama"
          />
        </li>
        <li className="logo-list-item mb-5">
          <img
            src="/assets/images/logo_yummy.png"
            className="max-w-full mx-auto"
            alt="logo yummy"
          />
        </li>
        <li className="logo-list-item mb-5">
          <img src="/assets/images/logo_ggwp.png" className="max-w-full mx-auto" alt="logo ggwp" />
        </li>
        <li className="logo-list-item mb-5">
          <img
            src="/assets/images/logo_duniaku.png"
            className="max-w-full mx-auto"
            alt="logo duniaku"
          />
        </li>
        <li className="logo-list-item mb-5">
          <img
            src="/assets/images/logo_fortune.png"
            className="max-w-full mx-auto"
            alt="logo fortune"
          />
        </li>
        <li className="logo-list-item mb-5">
          <img
            src="/assets/images/logo_ice.png"
            className="max-w-full mx-auto"
            alt="logo ice"
          />
        </li>
        <li className="logo-list-item">
          <img
            src="/assets/images/logo_idn_app.png"
            className="max-w-full mx-auto"
            alt="logo idnapp"
          />
        </li>
      </ul>
    </div>
  );
}

HeroImage.defaultProps = {
  type: 'small'
};
