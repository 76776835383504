import { ParsedUrlQuery } from 'querystring';

// https://gist.github.com/niyazpk/f8ac616f181f6042d1e0?permalink_comment_id=3797774#gistcomment-3797774
const updateUrlParameter = (uri: string, key: string, value: string | null | string[]) => {
  // remove the hash part before operating on the uri
  const i = uri.indexOf('#');
  const hash = i === -1 ? '' : uri.substr(i);
  uri = i === -1 ? uri : uri.substr(0, i);

  const re = new RegExp(`([?&])${key}=.*?(&|$)`, 'i');
  const separator = uri.indexOf('?') !== -1 ? '&' : '?';

  if (value === null) {
    // remove key-value pair if value is specifically null
    uri = uri.replace(new RegExp(`([?&]?)${key}=[^&]*`, 'i'), '');
    if (uri.slice(-1) === '?') {
      uri = uri.slice(0, -1);
    }
    // replace first occurrence of & by ? if no ? is present
    if (uri.indexOf('?') === -1) uri = uri.replace(/&/, '?');
  } else if (uri.match(re)) {
    uri = uri.replace(re, `$1${key}=${value}$2`);
  } else {
    uri = `${uri + separator + key}=${value}`;
  }
  return uri + hash;
};

export const GetPageLink = (link: string, query: ParsedUrlQuery): string => {
  let pathname = link;

  const { client_id, redirect_uri, authorization_code, forceAuth, state } = query;

  if (client_id) {
    pathname = updateUrlParameter(pathname, 'client_id', client_id);
  }

  if (redirect_uri) {
    pathname = updateUrlParameter(pathname, 'redirect_uri', redirect_uri);
  }

  if (authorization_code) {
    pathname = updateUrlParameter(pathname, 'authorization_code', authorization_code);
  }

  if (forceAuth) {
    pathname = updateUrlParameter(pathname, 'forceAuth', forceAuth);
  }

  if (state) {
    pathname = updateUrlParameter(pathname, 'state', state);
  }

  return pathname;
};