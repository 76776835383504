import HeroImage from '@/components/HeroImage';
import { GetPageLink } from '@/utility/getLinkPage';
import Link from 'next/link';
import { useRouter } from 'next/router';

interface LayoutProps {
  children: React.ReactNode;
  heroImageType: string;
  isPattern?: boolean
}

export default function Layout(props: LayoutProps) {
  const { children, heroImageType, isPattern = false } = props;
  const router = useRouter();

  return (
    <>
      <div className={`flex h-full w-full min-h-screen${isPattern ? ' bg-pattern bg-left-top bg-repeat' : ''}`}>
        <HeroImage type={heroImageType} />
        <div className="w-full block sm:flex items-center justify-center pt-6 sm:pt-16 pb-6 px-6 relative">
          <div className="w-full max-w-full sm:max-w-350px">
            <Link href={GetPageLink('/', router.query)}>
              <a>
                <img
                  src="/assets/images/logo_idn.svg"
                  width="41"
                  className="sm:hidden h-auto max-w-full mt-8 mb-12"
                  alt="idnmedia logo"
                />
              </a>
            </Link>
            {children}
          </div>
        </div>
      </div>
    </>
  );
}
