import React from 'react';
import Link from 'next/link';

interface ButtonProps {
  href?: string;
  as?: string;
  mergeClass?: string;
  text: string | JSX.Element;
  type?: string;
  size?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  width?: string;
  // for ...otherProps
  [x: string]: any;
}

export default function Button(props: ButtonProps) {
  const {
    href = '',
    as,
    mergeClass,
    text,
    type,
    size,
    onClick,
    disabled,
    width = 'full-width',
    ...otherProps
  } = props;

  let classSize = '';
  switch (size) {
    case 'small':
      classSize += ' text-size12 leading-15px py-2 px-4';
      break;
    default:
      classSize += ' text-size14 leading-17px py-4 px-6';
      break;
  }

  const getClassName = () => {
    let className = `${
      width == 'auto' ? 'w-auto' : 'w-full'
    } block font-lato ${classSize} rounded-lg bg-ottoman_red text-white shadow-btn_default hover:bg-ottoman_red_dark focus:outline-none disabled:cursor-not-allowed disabled:opacity-40 disabled:shadow-none transition-all`;

    if (mergeClass) {
      className += ` ${mergeClass}`;
    }

    return className;
  };

  return type == 'link' ? (
    <Link href={href} as={as}>
      <a className={getClassName()} {...otherProps}>
        {text}
      </a>
    </Link>
  ) : type === 'submit' ? (
    <button
      type="submit"
      disabled={disabled}
      onClick={onClick}
      className={getClassName()}
      {...otherProps}
    >
      {text}
    </button>
  ) : (
    <button disabled={disabled} onClick={onClick} className={getClassName()} {...otherProps}>
      {text}
    </button>
  );
}
