import React from 'react';

interface FormInputProps {
  label: string;
  type: string;
  isError: boolean;
  defaultValue?: string;
  children?: React.ReactNode;
  size?: string;
  placeholder?: string;
  paddingRightLabel?: string;
  // for ...otherProps
  [x: string]: any;
}

export default function FormInput(props: FormInputProps) {
  const {
    defaultValue,
    children,
    label,
    type,
    size,
    placeholder,
    isError,
    paddingRightLabel,
    ...otherProps
  } = props;

  return (
    <>
      <div
        className={`inline-block relative w-full ${
          size === 'small' ? 'h-50px form-input-small' : 'h-56px form-input'
        }`}
      >
        <input
          type={type}
          defaultValue={defaultValue}
          className={`block w-full ${
            isError ? 'border border-ottoman_red' : 'border border-transparent'
          } placeholder-transparent bg-flash_white disabled:bg-calla_lily rounded-lg text-dark_willow disabled:text-silver_charm disabled:cursor-not-allowed focus:outline-none focus:ring-0 px-4 ${
            size === 'small'
              ? 'text-size14 leading-22px pt-5 pb-2'
              : 'text-size16 leading-24px pt-24px pb-8px'
          }`}
          placeholder={placeholder}
          {...otherProps}
        ></input>
        <label
          className={`absolute ${
            isError ? 'label-error text-ottoman_red' : 'text-silver_charm'
          } pointer-events-none transform origin-left transition-all duration-100 ease-in-out line-clamp-1 leading-18px ${
            size === 'small' ? 'text-size14 top-16px left-4' : 'text-size16 top-18px left-4'
          } ${paddingRightLabel ? paddingRightLabel : ''}`}
        >
          {label}
        </label>
        {children}
      </div>
    </>
  );
}

FormInput.defaultProps = {
  label: '',
  type: 'text',
  placeholder: 'Isi Form...',
  isError: false
};
