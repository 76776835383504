export const getPortal = (host: string): string => {
  switch (true) {
    case host.includes('popmama'):
      return 'popmama';

    case host.includes('idntimes'):
      return 'idn-times';

    case host.includes('popbela'):
      return 'popbela';

    default:
      return 'idn-media';
  }
};
